import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { createContext, useEffect, useMemo, useState } from 'react'
import { colors } from '../style/colors'
import { useContext } from 'react'

const themeSettings = mode => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode: "light",

      primary: {
        main: '#ff66c4',
      },
      secondary: {
        main: '##f86b14',
      },
      neutral: {
        dark: '#000000',
        light: '#ffffff',
        main: '#f4f6f8',
      },
      background: {
        default: colors.white,
        paper: colors.white,
      },
      grey: colors.grey,

      contrastThreshold: 5,
    },
    spacing: 8,
    typography: {
      fontFamily: [
        '"Hiragino Sans"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 12,
      h1: { fontSize: 64, fontWeight: 700 },
      h2: { fontSize: 48, fontWeight: 700 },
      h3: { fontSize: 36, fontWeight: 700 },
      h4: { fontSize: 24, fontWeight: 700 },
      h5: { fontSize: 20, fontWeight: 700 },
      h6: { fontSize: 16, fontWeight: 700 },
      subtitle1: { fontSize: 16, fontWeight: 400 },
      subtitle2: { fontSize: 14, fontWeight: 400 },
      body1: { fontSize: 16, fontWeight: 400 },
      body2: { fontSize: 14, fontWeight: 400 },
      button: { fontSize: 14, fontWeight: 700 },
      caption: { fontSize: 12, fontWeight: 400 },
      overline: { fontSize: 12, fontWeight: 400 },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {},
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
          textTransform: 'uppercase',
        },
      },
    },
  })
}

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  mode: '',
})
export const useColorMode = () => {
  const { mode, toggleColorMode } = useContext(ColorModeContext)
  return { mode, toggleColorMode }
}

export default function ThemeProvider({ children }) {
  const modeFromLocalStorage = window.localStorage.getItem('colorMode')
  const [mode, setMode] = useState(modeFromLocalStorage || 'dark')

  useEffect(() => {
    window.localStorage.setItem('colorMode', mode)
  }, [mode])

  const toggleColorMode = () => setMode(mode === 'dark' ? 'light' : 'dark')

  const theme = useMemo(() => themeSettings(mode), [mode]) //use memo may not be needed!!

  return (
    <ColorModeContext.Provider value={{ mode: mode, toggleColorMode: toggleColorMode }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  )
}
