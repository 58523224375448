import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useAuth } from 'context'
import Loading from 'components/common/loading'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useCategories, usePoints, useSubCategories } from 'api/packs'

const SUPacksCategory = () => {
  const { user } = useAuth()
  const { category } = useParams()
  const navigate = useNavigate()
  const [categoryName, setCategoryName] = useState('')

  const { data: categories } = useCategories()

  const { data: subCategories, isLoading } = useSubCategories(category)

  useEffect(() => {
    if (categories) {
      if (isNaN(Number(category))) navigate('/su-packs')
      const selectedCategory = categories.find(cat => cat.category_id === Number(category))
      if (selectedCategory) setCategoryName(selectedCategory.category_name)
      else navigate('/su-packs')
    }
  }, [categories])

  const { data: userPoints } = usePoints(user?.google_id)

  return (
    <div className="su-packs-container">
      <Button
        variant="contained"
        color="primary"
        href="/su-packs"
        style={{ color: 'white', gap: '10px' }}
      >
        <ArrowBack />
        Back
      </Button>
      <h2>{categoryName}</h2>
      <h2>Select a Subcategory</h2>
      <div className="su-packs-categories">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {subCategories &&
              subCategories.map(category => (
                <Link
                  to={`/su-packs/categories/${category.category_id}/${category.subcategory_id}`}
                  key={category.subcategory_id}
                  className="su-packs-category"
                >
                  <h1>{category.name}</h1>
                  <img src={category.imageurl} alt={category.name} />
                </Link>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

export default SUPacksCategory
