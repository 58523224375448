import axios from 'axios'
import { packsBaseUrl } from './http'
import { useMutation, useQuery } from 'react-query'

export const useCategories = (enabled = true) => {
  return useQuery(
    'su-pack-categories',
    async () => {
      const response = await axios.get('/packs/category', { baseURL: packsBaseUrl })
      return response.data
    },
    { enabled }
  )
}

export const usePoints = googleId =>
  useQuery('su-pack-user-points', async () => {
    if (!googleId) return 2000
    const response = await axios.get('/packs/user/' + googleId, { baseURL: packsBaseUrl })
    return response.data.points
  })

export const useSubCategories = (categoryId, enabled = true) => {
  return useQuery(
    ['su-pack-category', categoryId],
    async () => {
      const response = await axios.get(`/PACKS_ITEMS_getSubcategories?categoryid=${categoryId}`, {
        baseURL: packsBaseUrl,
      })
      return response.data
    },
    { enabled }
  )
}

export const useItems = subCategoryId => {
  return useQuery(['su-pack-items', subCategoryId], async () => {
    const response = await axios.get('/PACKS_ITEMS_getItems', { baseURL: packsBaseUrl })

    if (isNaN(Number(subCategoryId))) return []

    return response.data.filter(item => item.subcategory_id === Number(subCategoryId))
  })
}

export const useItem = itemId => {
  return useQuery(['su-pack-item-details', itemId], async () => {
    const response = await axios.get(`/packs/items/${itemId}`, { baseURL: packsBaseUrl })
    if (response.data.itemInfo && response.data.itemInfo.length === 0) return null
    return response.data.result
  })
}

export const useAddItem = ({ onSuccess, onError }) => {
  return useMutation(
    'su-pack-add-item',
    async ({ item_id, google_id, color, size }) => {
      const response = await axios.post(
        '/packs/user/addItem',
        {
          itemid: item_id,
          google_id,
          color,
          size,
        },
        { baseURL: packsBaseUrl }
      )
      return response.data
    },
    { onSuccess, onError }
  )
}

export const useGetCart = google_id => {
  return useQuery(['su-pack-get-cart', google_id], async () => {
    const response = await axios.get(`packs/useritemsubchoice/${google_id}`, {
      baseURL: packsBaseUrl,
    })
    return response.data
  })
}

export const useRemoveItem = ({ onSuccess, onError }) => {
  return useMutation(
    'su-pack-remove-item',
    async ({ google_id, id }) => {
      const response = await axios.get(
        `/PACKS_USER_removeItem?google_id=${google_id}&itemsubchoiceid=${id}`,
        {
          baseURL: packsBaseUrl,
        }
      )
      return response.data
    },
    { onSuccess, onError, retry: 0 }
  )
}

export const usePackAccess = google_id => {
  return useQuery(['su-pack-access', google_id], async () => {
    const response = await axios.get(`packs/admission/${google_id}`, {
      baseURL: packsBaseUrl,
    })
    return response.data
  })
}

export const useCheckout = ({ onSuccess, onError }) => {
  return useMutation(
    'su-pack-checkout',
    async google_id => {
      const response = await axios.put(
        `/packs/user/${google_id}`,
        {},
        {
          baseURL: packsBaseUrl,
        }
      )
      return response.data
    },
    { onSuccess, onError }
  )
}
