//protectedRoute.jsx

import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth, useToast } from 'context'

import Loading from 'components/common/loading'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, loading } = useAuth()
  const { error } = useToast()

  if (loading) return <Loading />

  if (!user) {
    error('You must be logged in to access this page')
    return <Navigate to="/" replace />
  }

  return <Component {...rest} />
}

export default ProtectedRoute
