import CardWithShadow from 'components/common/card/CardWithShadow'
import { TextField, Button, Stack } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { LoginOutlined } from '@mui/icons-material'
import { useAuth } from 'context'

const LogIn = () => {
  const { user, signIn } = useAuth()

  const SignInButton = ({ OnSignIn }) => (
    <Button
      sx={{ backgroundColor: '#fff' }}
      size="large"
      onClick={OnSignIn}
      variant="outlined"
      color="primary"
      startIcon={<LoginOutlined />}
    >
      Sign In
    </Button>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <CardWithShadow
        sx={{
          flex: 1,
          flexDirection: 'column',
          p: 10,
          display: 'flex',
          maxWidth: '80%',
          maxHeight: '80%',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            // flexGrow: 1,
            flex: 0.3,
            fontSize: { xs: 25, sm: 36 },
            fontWeight: 700,
            mb: 1,
          }}
        >
          Please sign to access this page.
        </Typography>
        <SignInButton OnSignIn={signIn} />
      </CardWithShadow>
    </Box>
  )
}

export default LogIn
