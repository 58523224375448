import { React } from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useToast } from 'context'
import { useAuth } from 'context/'
import { useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { createNote } from 'api/studynotes.service'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import x from 'api/http'
import { InputLabel } from '@mui/material'

export default function UploadNote() {
  const { category } = useParams()
  const location = useLocation()
  const courseId = location.state

  const { error, success } = useToast()
  const { user } = useAuth()
  const navigate = useNavigate()

  const uploadNoteApi = useMutation(createNote, {
    onSuccess: () => {
      success('Note submitted for review')
      navigate(`/study-notes/${category}/UploadNote`, { state: courseId })
    },
    onError: err => {
      error(err.response.data.message)
    },
  })

  const handleSubmit = event => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('pdf', event.target.uploadNote.files[0]) // Add the file to FormData
    formData.append('note_title', event.target.noteTitle.value)
    formData.append('course_id', courseId)

    uploadNoteApi.mutateAsync(formData)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>Upload Note</h1>
      <Box>
        <form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 3, mb: 3 }}>
            <FormControl
              sx={{
                minWidth: 300,
              }}
              required
            >
              <Input type="text" id="noteTitle" name="noteTitle" placeholder="Note Title" />
            </FormControl>
            <br />
            <FormControl
              sx={{
                minWidth: 300,
                mb: 2,
              }}
              required
            >
              <Input type="file" id="uploadNote" name="uploadNote" accept="application/pdf" />
            </FormControl>

            <Button id="submit" variant="outlined" color="secondary" type="submit">
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  )
}
