import http from './http'

const endPoint = '/regnotifs/'

export const postCRN = async ({ gid, CRNs }) => {
  const res = await http.post(endPoint, {
    gid,
    CRNs,
  })
  return res
}

export const getCRNs = async gid => {
  const res = await http.get(`${endPoint}${gid}`)
  return res
}

export const deleteCRN = async ({ gid, CRNs }) => {
  const res = await http.delete(endPoint, {
    data: {
      gid,
      CRNs,
    },
  })
  return res
}
// export
