import http from '../http'

export const getApplicant = async ({ queryKey }) => {
  const [, applicantId] = queryKey
  const res = (await http.get(`recruitment/applicants/${applicantId}`))?.data
  return res
}

export const searchApplicant = async ({ queryKey }) => {
  const [, searchKey] = queryKey
  const res = (
    await http.get(`recruitment/interviews/applicants/search`, {
      // query params
      params: {
        email: searchKey,
        studentId: searchKey,
      },
    })
  )?.data
  return res
}

export const getApplicantAvailabilePreferences = async ({ queryKey }) => {
  const [, googleId] = queryKey
  const res = (await http.get(`/recruitment/applicants/google/${googleId}/preferences`))?.data
  return res
}

export const makeDecision = async ({ applicantId, decision, preference_id, project_id }) => {
  console.log(
    'Sending decision',
    decision,
    'for applicant',
    applicantId,
    'with preference',
    preference_id
  )
  const res = await http.put(`/recruitment/applicants/${applicantId}/decision`, {
    decision,
    preference_id,
    project_id,
  })
  return res
}

export const insertApplicant = async ({
  id,
  major,
  minor,
  phone_number,
  classStanding,
  firstPref,
  secondPref,
  firstPrefProject,
  secondPrefProject,
}) => {
  const res = await http.post(`/recruitment/applicants/`, {
    id,
    major,
    minor,
    phone_number,
    classStanding,
    firstPref,
    secondPref,
    firstPrefProject,
    secondPrefProject,
  })
  return res
}

export const checkApplicant = async () => {
  const res = await http.get(`/recruitment/user-filled-form`)
  return res
}
