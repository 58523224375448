import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { LoginOutlined } from '@mui/icons-material'
import { useAuth } from 'context'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tab,
} from '@mui/material'
import CardWithShadow from 'components/common/card/CardWithShadow'
import { Box } from '@mui/material'
import { useToast } from 'context'
import { postCRN } from 'api/coursevacancies.service'
import { getCRNs } from 'api/coursevacancies.service'
import { deleteCRN } from 'api/coursevacancies.service'
import { useMutation } from 'react-query'
import { useQuery } from 'react-query'
import { useEffect } from 'react'

const CourseTracker = () => {
  const { user, signIn } = useAuth()
  const [courseCRN, setCourseCRN] = useState('')
  const [submittedCRNs, setSubmittedCRNs] = useState([])
  const { success, error } = useToast()
  const [returnedCRNs, setReturnedCRNs] = useState([])

  // post API
  const postCRNApi = useMutation(postCRN, {
    onSuccess: data => {
      success('CRN submitted successfully!')
      window.location.reload()
    },
    onError: error => {
      error('CRN submission failed!')
    },
  })

  function postCRNFunction() {
    postCRNApi.mutate({ CRNs: courseCRN, gid: user.google_id })
  }

  // get API
  const getCRNApi = useMutation(getCRNs, {
    onSuccess: data => {
      // if the length of submittedCRNs is 0, then we dont do success
      if (submittedCRNs.length !== 0) {
        success('CRN gotten successfully!')
      }
      // newArr =
      data.data.data.map(course =>
        setSubmittedCRNs(oldArray => [
          ...oldArray,
          <TableRow key={course.CRN}>
            <TableCell style={{ fontSize: '17px' }}>{course.CRN}</TableCell>
            <TableCell style={{ fontSize: '17px' }}>{course.courseName}</TableCell>
            <TableCell style={{ fontSize: '17px' }}>{course.instructorName}</TableCell>
            <TableCell style={{ fontSize: '17px' }}>
              {course.courseDays}
              <br /> {course.courseTimings}
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteCRNFunction(course.CRN)}
                // style={{
                //   color: 'red',
                //   fontSize: '15px',
                //   backgroundColor: 'white',
                //   borderRadius: '8px',
                //   border: '3px solid red',
                // }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>,
        ])
      )
    },
    onError: error => {
      error('CRN get fail!')
    },
  })

  function getCRNFunction() {
    getCRNApi.mutate(user.google_id)
  }

  //DELETE API
  const deleteCRNApi = useMutation(deleteCRN, {
    onSuccess: data => {
      success('CRN deleted successfully!')
      window.location.reload()
    },
    onError: error => {
      error('CRN delete fail!')
    },
  })

  function deleteCRNFunction(crn) {
    deleteCRNApi.mutate({ CRNs: crn, gid: user.google_id })
  }

  //first thing to do when loading the page, load the CRNs
  useEffect(() => {
    // if user is not null, then we can get the CRNs
    if (user !== null) {
      getCRNFunction()
    }
  }, [])

  const SignInButton = ({ OnSignIn }) => (
    <Button
      sx={{ backgroundColor: '#fff' }}
      size="large"
      onClick={OnSignIn}
      variant="outlined"
      color="primary"
      startIcon={<LoginOutlined />}
    >
      Sign In
    </Button>
  )

  // set submitted crns gets filled by the GET API request

  const handleSubmit = event => {
    event.preventDefault()

    if (/^\d{5}$/.test(courseCRN)) {
      if (!submittedCRNs.includes(courseCRN)) {
        postCRNFunction()
        //reload the page
        setCourseCRN('')
      } else {
        error('CRN is already submitted')
      }
    } else {
      error('Please enter a valid 5-digit CRN')
    }
  }

  if (user === null) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <CardWithShadow
          sx={{
            flex: 1,
            flexDirection: 'column',
            p: 10,
            display: 'flex',
            maxWidth: '80%',
            maxHeight: '80%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              flex: 0.3,
              fontSize: { xs: 25, sm: 36 },
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            Please sign in to access the form.
          </Typography>
          <SignInButton OnSignIn={signIn} />
        </CardWithShadow>
      </Box>
    )
  } else {
    return (
      <div>
        <Box
          sx={{
            maxWidth: '400px',
            margin: '20px auto 0',
            padding: '20px',
            backgroundColor: '#f0f0f0',
            border: '1px solid #ccc',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 500 }}>
            Add the CRN of the course you want to be notified about when a seat opens up. You will
            receive emails of freed slots up until the add/drop period ends or once you remove the
            course CRN from the list
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            maxWidth: '400px',
            margin: '0 auto',
            marginTop: '50px',
            border: '1px solid #ccc',
            padding: '20px',
          }}
        >
          <Stack spacing={2}>
            <TextField
              label="Course CRN"
              variant="outlined"
              fullWidth
              value={courseCRN}
              onChange={e => setCourseCRN(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}
          >
            Submit
          </Button>
        </form>
        <TableContainer
          component={Paper}
          style={{
            maxWidth: '1000px',
            margin: '20px auto',
            padding: '20px',
            marginBottom: '50px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CRN</TableCell>
                <TableCell>Course Name</TableCell>
                <TableCell>Course Instructor</TableCell>
                <TableCell>Course Timings</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {submittedCRNs.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    You have not added any courses to be tracked yet!
                  </TableCell>
                </TableRow>
              ) : (
                submittedCRNs
              )}
              {/* {submittedCRNs} */}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          variant="body1"
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          Special thanks for <a href="https://github.com/yehiaabdelm/">Yehia Abdelmohsen</a> for the idea!
        </Typography>
      </div>
    )
  }
}

export default CourseTracker
