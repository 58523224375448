import http from './http'

export const getCategory = async () => {
  const result = await http.get('/notes/categories')
  console.log('RESULT: ', result)
  return result
}

export const getCourses = async () => {
  const result = await http.get('/notes/courses')
  // console.log('RESULT: ', result)
  return result
}

export const getNotes = async id => {
  const result = await http.get(`/notes/coursenotes/${id}`)
  // console.log('RESULT: ', result)
  return result
}

export const createNote = async data => {
  const result = await http.post('/notes', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return result
}
