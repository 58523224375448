import { Box } from '@mui/system'
import CardWithShadow from 'components/common/card/CardWithShadow'
import { Typography } from '@mui/material'

const ComingSoon = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <CardWithShadow
        sx={{
          flex: 1,
          flexDirection: 'column',
          p: 10,
          display: 'flex',
          maxWidth: '80%',
          maxHeight: '80%',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            // flexGrow: 1,
            flex: 0.3,
            fontSize: 50,
            fontWeight: 700,
          }}
        >
          Coming Soon...
        </Typography>
        {/* <Typography
          variant="h4"
          component="div"
          sx={{
            flex: 0.3,
            fontSize: 36,
            fontWeight: 700,
          }}
        >
          We are working on it
        </Typography>
        <Typography
          variant="h4"
          component="div"
          sx={{
            flex: 0.3,
            fontSize: 36,
            fontWeight: 700,
          }}
        >
          Please check back later
        </Typography> */}
      </CardWithShadow>
    </Box>
  )
}

export default ComingSoon
