import React, { useState } from 'react'
import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useAuth } from 'context'
import Loading from 'components/common/loading'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import './style.css'
import { useAddItem, useItem, usePoints } from 'api/packs'
import AreYouSureBro from 'components/common/are-you-sure-bro'
import { toast } from 'react-toastify'

const SUPacksItem = () => {
  const { user } = useAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const [modalOptions, setModalOptions] = useState({ open: false, size: '', color: '' })
  const queryClient = useQueryClient()

  const { data: itemData, isLoading } = useItem(id)
  const { mutate: addItem, isLoading: isAddingItem } = useAddItem({
    onSuccess: () => {
      queryClient.invalidateQueries('su-pack-user-points')
      queryClient.invalidateQueries(['su-pack-item-details', id])
      setModalOptions({ open: false, size: '', color: '' })
      toast.success('Item added to cart successfuly!')
      navigate('/su-packs')
    },
    onError: () => {},
  })

  const itemDetails = itemData?.itemInfo[0]
  const subChoices = itemData?.subChoices

  const { data: userPoints } = usePoints(user?.google_id)

  return (
    <div className="su-packs-container">
      <Button
        variant="contained"
        color="primary"
        href={`/su-packs/`}
        style={{ color: 'white', gap: '10px' }}
      >
        <ArrowBack />
        Back
      </Button>
      <div className="su-packs-item-details">
        {isLoading ? (
          <Loading />
        ) : (
          itemDetails &&
          subChoices && (
            <>
              <h2>{itemDetails.item_name}</h2>
              <img src={itemDetails.imageURL} alt={itemDetails.item_name} />
              <p
                style={{
                  color: userPoints < itemDetails.price ? '#ff0000' : '#48b902',
                  fontWeight: 600,
                }}
              >
                Price: {itemDetails.price} points (
                {userPoints < itemDetails.price ? 'Yikes' : 'Affordable'})
              </p>
              <div>
                <h2>Available Choices</h2>
                <div className="su-packs-item-choices">
                  {subChoices.map(choice => (
                    <div
                      className="choice-card"
                      style={{
                        opacity: choice.quantity < 0 && choice.quantity !== -100 ? 0.5 : 1,
                        borderColor: choice.quantity === -100 && '#ff66c4',
                      }}
                      key={choice.ID}
                    >
                      {choice.color && <p>Color: {choice.color}</p>}
                      {choice.size && <p>Size: {choice.size}</p>}
                      <p>
                        {choice.quantity > 0
                          ? `${choice.quantity} item${choice.quantity !== 1 ? 's' : ''} left`
                          : choice.quantity === -100
                          ? 'Unlimited'
                          : 'Out of stock :('}{' '}
                      </p>

                      <Button
                        variant="contained"
                        color="primary"
                        disabled={userPoints < itemDetails.price}
                        onClick={() => {
                          setModalOptions({ open: true, size: choice.size, color: choice.color })
                        }}
                      >
                        Select
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <AreYouSureBro
                open={modalOptions.open}
                setOpen={o => {
                  setModalOptions({ ...modalOptions, open: o })
                }}
                title={`Are you sure you want to add ${itemDetails.item_name} to your cart?`}
                description={`This will reduce your points to ${
                  userPoints - itemDetails.price
                } points.
            ${
              modalOptions.size && modalOptions.color
                ? `Size: ${modalOptions.size}, Color: ${modalOptions.color}`
                : ''
            }`}
                loading={isAddingItem}
                onClick={() => {
                  addItem({
                    google_id: user.google_id,
                    item_id: itemDetails.item_id,
                    size: modalOptions.size,
                    color: modalOptions.color,
                  })
                }}
              />
            </>
          )
        )}
      </div>
    </div>
  )
}

export default SUPacksItem
