import { React, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import LogIn from './login'
import { useAuth } from 'context'

export default function CourseList() {
  const { user, signIn } = useAuth()

  const { category } = useParams()

  const navigate = useNavigate()

  const [courses, setCourses] = useState([])
  const [displayCourses, setDisplayCourses] = useState([])

  useEffect(() => {
    fetch('https://api.aucsu.org/api/v1/notes/courses')
      .then(response => response.json())
      .then(data => {
        data.data.map(course => {
          if (course.crs_prefix === category) {
            setCourses(oldArray => [
              ...oldArray,
              {
                crs_ID: course.crs_ID,
                crs_name: course.crs_name,
              },
            ])
          }
        })
      })
  }, [])

  useEffect(() => {
    courses.map(course => {
      setDisplayCourses(oldArray => [
        ...oldArray,
        <Card
          key={course.crs_ID}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 175,
            height: 175,
            // ml: 2,
            // mb: 2,
          }}
        >
          <CardContent>
            <Typography
              // variant="h5"
              component="h2"
              sx={{
                fontSize: 15,
                alignItems: 'center',
              }}
              gutterBottom
            >
              {course.crs_name}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => navigate(`/study-notes/${category}/${course.crs_ID}`)}
            >
              View Notes
            </Button>
            <Button
              size="small"
              onClick={() =>
                navigate(`/study-notes/${category}/UploadNote`, { state: course.crs_ID })
              }
            >
              Add Note
            </Button>
          </CardActions>
        </Card>,
      ])
    })
  }, [courses])

  if (!user) return <LogIn />
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom sx={{ mt: 2, mb: 5 }}>
        {category}
      </Typography>
      <Box
        id="card-container"
        sx={{
          mx: 4,
          mb: 7,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {displayCourses}
      </Box>
    </Box>
  )
}
