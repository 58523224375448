import React from 'react'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { LoginOutlined, ShoppingCart } from '@mui/icons-material'
import { useAuth } from 'context'
import Loading from 'components/common/loading'
import './style.css'
import { Link, Outlet } from 'react-router-dom'
import { useCategories, usePackAccess, usePoints } from 'api/packs'

const CardWithShadow = props => {
  const { sx, children, ...rest } = props
  return (
    <Box
      sx={{
        borderRadius: '100px',
        display: 'flex',
        border: '1px solid #000',
        flex: 1,
        width: '100%',
        boxShadow: '25px 25px grey',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const SignInButton = ({ onClick }) => (
  <Button
    sx={{ backgroundColor: '#fff' }}
    size="large"
    onClick={onClick}
    variant="outlined"
    color="primary"
    startIcon={<LoginOutlined />}
  >
    Sign In
  </Button>
)

const SignInCard = ({ children, hidebutton }) => {
  const { signIn } = useAuth()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
      }}
    >
      <CardWithShadow
        sx={{
          flex: 1,
          flexDirection: 'column',
          p: 10,
          display: 'flex',
          maxWidth: '80%',
          maxHeight: '80%',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            flex: 0.3,
            fontSize: { xs: 25, sm: 36 },
            fontWeight: 700,
            marginBottom: 2,
          }}
        >
          {children}
        </Typography>
        {!hidebutton && <SignInButton onClick={signIn} />}
      </CardWithShadow>
    </Box>
  )
}

export const SUPacks = () => {
  const { data: categories, isLoading } = useCategories()

  return (
    <div className="su-packs-container">
      <h2>Select a Category</h2>
      <div className="su-packs-categories">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {categories &&
              categories.map(category => (
                <Link
                  to={`/su-packs/categories/${category.category_id}`}
                  key={category.category_id}
                  className="su-packs-category"
                >
                  <h1>{category.category_name}</h1>
                  <img src={category.imageURL} alt={category.category_name} />
                </Link>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

// This is the SU Packs Shop page
const SUPacksWrapper = () => {
  const { user } = useAuth()
  const { data: userPoints, isLoading } = usePoints(user?.google_id)
  const { data: access, isLoading: ispackaccessloading } = usePackAccess(user?.google_id)
  if (!user) return <SignInCard children={<>Please sign in to access the SU Packs.</>} />
  if (!ispackaccessloading && access.orderNum)
    return (
      <SignInCard
        hidebutton="1"
        children={
          <>
            <Typography variant="h1" component="div">
              You have already ordered a pack.
              <br />
              Your order number is <br /> {access.orderNum}
            </Typography>
          </>
        }
      />
    )
  if (!ispackaccessloading && !access.packsAvail)
    return <SignInCard hidebutton="1" children={<>"Packs are closed at the moment"</>} />
  if (!ispackaccessloading && !access.hasPack)
    return (
      <SignInCard
        hidebutton="1"
        children={<>You are not allowed to sign for Packs Goodluck Next year! :(</>}
      />
    )

  if (isLoading) return <Loading></Loading>
  return (
    <div className="su-packs-main">
      <div className="header">
        <div>
          <h1>Customize your SU Pack</h1>
          <div className="points">
            <p>
              Points Remaining:{' '}
              <span style={{ color: '#40e731' }}>
                {isLoading ? <CircularProgress /> : userPoints}
              </span>
            </p>
            <Link to="/su-packs/checkout">
              <Button
                color="primary"
                variant="contained"
                style={{ display: 'flex', gap: '1rem', color: 'white' }}
              >
                <ShoppingCart /> View Cart & Checkout
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default SUPacksWrapper

// Su Pack Registration (this step is for the user to fill out the form before they can access the shop)

// const MessageBox = ({ message }) => {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flex: 1,
//         height: '100%',
//         width: '100%',
//       }}
//     >
//       <CardWithShadow
//         sx={{
//           flex: 1,
//           flexDirection: 'column',
//           p: 10,
//           display: 'flex',
//           maxWidth: '80%',
//           maxHeight: '80%',
//           textAlign: 'center',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <Typography
//           variant="h1"
//           component="div"
//           sx={{
//             flex: 0.3,
//             fontSize: { xs: 25, sm: 36 },
//             fontWeight: 700,
//           }}
//         >
//           {message}
//         </Typography>
//       </CardWithShadow>
//     </Box>
//   )
// }

// const SUPacksRegistration = () => {
//   const { user, loading, signIn } = useAuth()
//   const [formClosed, setFormClosed] = useState(false)
//   const [loadingFormStatus, setLoadingFormStatus] = useState(true)
//   const [submitted, setSubmitted] = useState(false)
//   const [success, setSuccess] = useState(false)

//   React.useEffect(() => {
//     const checkFormStatus = async () => {
//       try {
//         const response = await x.get('/regpacks/is-form-closed')
//         setFormClosed(response.data.data.closed)
//       } catch (error) {
//         console.error('Error checking form status', error)
//       } finally {
//         setLoadingFormStatus(false)
//       }
//     }

//     checkFormStatus()
//   }, [])

//   React.useEffect(() => {
//     if (!user) return
//     x.get('/regpacks/user-filled-form', { params: { google_id: user.google_id } }).then(res => {
//       console.log(res)
//       if (res.data.data == true) {
//         setSubmitted(true)
//       }
//     })
//   })

//   if (loading) {
//     return <div>Loading...</div>
//   } else if (success) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flex: 1,
//           height: '100%',
//           width: '100%',
//         }}
//       >
//         <CardWithShadow
//           sx={{
//             flex: 1,
//             flexDirection: 'column',
//             p: 10,
//             display: 'flex',
//             maxWidth: '80%',
//             maxHeight: '80%',
//             textAlign: 'center',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <Typography
//             variant="h1"
//             component="div"
//             sx={{
//               flex: 0.3,
//               fontSize: { xs: 25, sm: 36 },
//               fontWeight: 700,
//             }}
//           ></Typography>
//         </CardWithShadow>
//       </Box>
//     )
//   } else if (submitted) {
//     return <MessageBox message="You Already Filled This Form." />
//   } else if (!user) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flex: 1,
//           height: '100%',
//           width: '100%',
//         }}
//       >
//         <CardWithShadow
//           sx={{
//             flex: 1,
//             flexDirection: 'column',
//             p: 10,
//             display: 'flex',
//             maxWidth: '80%',
//             maxHeight: '80%',
//             textAlign: 'center',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <Typography
//             variant="h1"
//             component="div"
//             sx={{
//               flex: 0.3,
//               fontSize: { xs: 25, sm: 36 },
//               fontWeight: 700,
//             }}
//           >
//             Please sign in to access the form.
//           </Typography>
//           <SignInButton onSignIn={signIn} />
//         </CardWithShadow>
//       </Box>
//     )
//   } else if (formClosed)
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flex: 1,
//           height: '100%',
//           width: '100%',
//         }}
//       >
//         <CardWithShadow
//           sx={{
//             flex: 1,
//             flexDirection: 'column',
//             p: 10,
//             display: 'flex',
//             maxWidth: '80%',
//             maxHeight: '80%',
//             textAlign: 'center',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <Typography
//             variant="h1"
//             component="div"
//             sx={{
//               flex: 0.3,
//               fontSize: { xs: 25, sm: 36 },
//               fontWeight: 700,
//             }}
//           >
//             Form is closed. Please contact the SU Packs team for more information.
//           </Typography>
//         </CardWithShadow>
//       </Box>
//     )
//   else if (!formClosed && submitted === false)
//     return (
//       <div className="container">
//         <h1>SU Packs Registration</h1>
//         <Formik
//           initialValues={{
//             google_id: user.google_id,
//             first_name: '',
//             last_name: '',
//             student_id: '',
//             phone_number: '',
//             gender: '',
//             class_standing: '',
//             major: '',
//             consent: false,
//           }}
//           onSubmit={values => {
//             setLoadingFormStatus(true)
//             x.post('/regpacks/register', values).then(data => {
//               setLoadingFormStatus(false)
//               setSuccess(true)
//             })
//             console.log('Form Data:', values)
//           }}
//         >
//           {({ handleSubmit }) => (
//             <Form onSubmit={handleSubmit}>
//               {/* Name Fields */}
//               <div className="name-container">
//                 <Field
//                   component={TextField}
//                   name="first_name"
//                   label="First Name"
//                   variant="outlined"
//                   required
//                   fullWidth
//                 />
//                 <Field
//                   component={TextField}
//                   name="last_name"
//                   label="Last Name"
//                   variant="outlined"
//                   required
//                   fullWidth
//                 />
//               </div>

//               {/* ID Field */}
//               <div className="id-container">
//                 <Field
//                   component={TextField}
//                   name="student_id"
//                   label="ID"
//                   variant="outlined"
//                   required
//                   fullWidth
//                 />
//               </div>

//               {/* Phone Number Field */}
//               <div className="phone-no-container">
//                 <Field
//                   component={TextField}
//                   name="phone_number"
//                   label="Phone Number"
//                   variant="outlined"
//                   required
//                   fullWidth
//                 />
//               </div>

//               {/* Gender Select Field */}
//               <div className="gender-container">
//                 <Box sx={{ minWidth: 120 }}>
//                   <FormControl fullWidth>
//                     <Field
//                       component={Select}
//                       name="gender"
//                       label="Gender"
//                       inputProps={{
//                         id: 'gender-select',
//                       }}
//                       fullWidth
//                     >
//                       <MenuItem value={'Male'}>Male</MenuItem>
//                       <MenuItem value={'Female'}>Female</MenuItem>
//                     </Field>
//                   </FormControl>
//                 </Box>
//               </div>

//               {/* Class Standing Select Field */}
//               <div className="class-standing-container">
//                 <Box sx={{ minWidth: 120 }}>
//                   <FormControl fullWidth>
//                     <Field
//                       component={Select}
//                       name="class_standing"
//                       label="Class Standing"
//                       inputProps={{
//                         id: 'class_standing',
//                       }}
//                       fullWidth
//                     >
//                       <MenuItem value={'Freshman'}>Freshman</MenuItem>
//                       <MenuItem value={'Sophomore'}>Sophomore</MenuItem>
//                       <MenuItem value={'Junior'}>Junior</MenuItem>
//                       <MenuItem value={'Senior'}>Senior</MenuItem>
//                       <MenuItem value={'Graduating-Senior'}>Graduating Senior</MenuItem>
//                     </Field>
//                   </FormControl>
//                 </Box>
//               </div>

//               {/* Major Field */}
//               <div className="major-container">
//                 <Field
//                   component={TextField}
//                   name="major"
//                   label="Major"
//                   variant="outlined"
//                   required
//                   fullWidth
//                 />
//               </div>

//               {/* Consent Field */}
//               <div className="consent-container">
//                 <Field component={Checkbox} name="consent" type="checkbox" />
//                 <span>I consent to my number being sent to external companies</span>
//               </div>

//               {/* Submit Button */}
//               <div className="button-container">
//                 <Button variant="contained" type="submit">
//                   Submit
//                 </Button>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </div>
//     )
// }

// export default SUPacksRegistration
