import http from './http'
import jwtDecode from 'jwt-decode'

const tokenKey = 'token'

http.setAuthHeader(localStorage.getItem(tokenKey))

const login = async idToken => {
  try {
    const {
      data: { data: jwt },
    } = await http.post(`/auth/login`, { idToken })
    http.setAuthHeader(jwt)
    localStorage.setItem(tokenKey, jwt)

    const user = getCurrentUser()
    return user
  } catch (err) {
    console.error('Caught in login: ', err)
    throw err
  }
}

const logout = async () => {
  localStorage.removeItem(tokenKey)
  http.removeAuthHeader()
}

const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(tokenKey)
    if (!jwt) return null
    console.log(jwtDecode(jwt))
    return jwtDecode(jwt)
  } catch (err) {
    console.error('Caught in jwtDecode: ', err)
  }
}

const authService = {
  login,
  logout,
  getCurrentUser,
}

export default authService
