import React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import PDFViewer from 'pdf-viewer-reactjs'
import { Typography } from '@mui/material'

import styles from './styles.css'

export default function NoteCard({ note }) {
  const doc = React.useMemo(() => ({ url: note.url }), [note])
  // console.log(doc)
  return (
    <Card
      key={note.note_id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f1f1f1',
        // height: { xs: 'auto', md: '100%' },
        // width: { xs: '90%', md: 'auto' },
      }}
    >
      <CardContent
        sx={{
          width: { xs: '90%', md: 'auto' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          //backgroundColor: 'pink',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: 16,
            textAlign: 'start',
            pl: 1,
          }}
        >
          {note.note_title}
        </Typography>
        <iframe src={note.url} width="100%" height="100%" style={{ border: 'none' }}></iframe>
      </CardContent>
      <CardActions
        sx={{
          backgroundColor: '#f1f1f1',
          padding: 0,
        }}
      >
        <Button
          size="medium"
          onClick={() => {
            window.open(note.url, '_blank')
          }}
          sx={{
            borderRadius: 1,
            border: 1,
            borderColor: '#ff66c4',
            backgroundColor: '#ff66c4',
            mb: 2,
            color: '#fff',
            fontSize: 12,
            minWidth: { xs: '75vw', sm: '64px' },
            '&:hover': {
              backgroundColor: '#fff',
              color: '#ff66c4',
            },
          }}
        >
          Download Note
        </Button>
      </CardActions>
    </Card>
  )
}
