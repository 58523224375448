import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useAuth } from 'context'
import Loading from 'components/common/loading'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useItems, usePoints, useSubCategories } from 'api/packs'

const SUPacksSubcategory = () => {
  const { user } = useAuth()
  const { subCategory, category } = useParams()
  const navigate = useNavigate()
  const [subCategoryDetails, setSubCategoryDetails] = useState({
    name: '',
    category_id: 0,
    id: 0,
    imageurl: '',
  })

  const { data: subCategories } = useSubCategories(category)

  const { data: items, isLoading } = useItems(subCategory)

  useEffect(() => {
    if (subCategories) {
      if (isNaN(Number(subCategory))) navigate('/su-packs')
      const selectedSubcategory = subCategories.find(
        cat => cat.subcategory_id === Number(subCategory)
      )
      if (selectedSubcategory) {
        setSubCategoryDetails({
          name: selectedSubcategory.name,
          category_id: selectedSubcategory.category_id,
          id: selectedSubcategory.subcategory_id,
          imageurl: selectedSubcategory.imageurl,
        })
      } else navigate('/su-packs')
    }
  }, [subCategories])

  const { data: userPoints } = usePoints(user?.google_id)

  return (
    <div className="su-packs-container">
      <Button
        variant="contained"
        color="primary"
        href={`/su-packs/categories/${subCategoryDetails.category_id}`}
        style={{ color: 'white', gap: '10px' }}
      >
        <ArrowBack />
        Back
      </Button>
      <div className="logo-container">
        <img src={subCategoryDetails.imageurl} style={{ width: '200px' }} />
      </div>
      <h2>{subCategoryDetails.name}</h2>
      <h2>Browse Items</h2>
      <div className="su-packs-items">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {items &&
              items.map(item => (
                <Link
                  to={`/su-packs/items/${item.item_id}`}
                  key={item.item_id}
                  className="su-packs-item"
                >
                  <h1>{item.item_name}</h1>
                  <img src={item.imageURL} alt={item.item_name} />
                  <p
                    style={{
                      color: userPoints < item.price ? '#ff0000' : '#48b902',
                    }}
                  >
                    {item.price} points
                  </p>
                </Link>
              ))}
          </>
        )}
      </div>
    </div>
  )
}

export default SUPacksSubcategory
