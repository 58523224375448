import {
  QueryClientProvider,
  useQueryClient as useReactQueryClient,
  QueryClient,
} from 'react-query'

const queryClient = new QueryClient()

export const useQueryClient = () => {
  return useReactQueryClient()
}
const ReactQueryProvider = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
export default ReactQueryProvider
