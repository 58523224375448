import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { TextField, Button, Stack } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { Link } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import { useAuth } from 'context'
import { useMutation } from 'react-query'
import { insertApplicant } from 'api/recruitment/applicants.service'
import './index.css'
import { useToast } from 'context'
import CardWithShadow from 'components/common/card/CardWithShadow'
import { Typography } from '@mui/material'
import { LoginOutlined } from '@mui/icons-material'
import x from 'api/http'

const RecruitmentForm = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [id, setId] = useState('')
  const [standing, setStanding] = useState('')
  const [major, setMajor] = useState('')
  const [minor, setMinor] = useState('')
  const [firstPref, setFirstPref] = useState('')
  const [secondPref, setSecondPref] = useState('')
  const [committees, setCommittees] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [firstProj, setFirstProj] = useState('')
  const [secondProj, setSecondProj] = useState('')
  const [projects, setProjects] = useState([])
  const [projects2, setProjects2] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [status, setStatus] = useState()

  const { error, success } = useToast()
  const { user, signIn } = useAuth()
  console.log(user);

  const SignInButton = ({ OnSignIn }) => (
    <Button
      sx={{ backgroundColor: '#fff' }}
      size="large"
      onClick={OnSignIn}
      variant="outlined"
      color="primary"
      startIcon={<LoginOutlined />}
    >
      Sign In
    </Button>
  )

  React.useEffect(() => {
    fetch('https://api.aucsu.org/api/v1/recruitment/config')
      .then(response => response.json())
      .then(data => setStatus(data.data.formOpen))
  }, [])

  React.useEffect(() => {
    if (!user) return
    x.get('/recruitment/user-filled-form').then(response => {
      // console.log('response:', response.data.data)
      if (response.data.data.length > 0) {
        setSubmitted(true)
      }
    })
  }, [user])

  const insertApplicantApi = useMutation(insertApplicant, {
    onError: err => {
      error('Something went wrong, please try again')
      setSubmitted(false)
    },
    onSuccess: data => {
      success('Form submitted successfully')
      setSubmitted(true)
    },
  })

  function submitApplicant() {
    // console.log('submitting')
    insertApplicantApi.mutateAsync({
      id: id,
      major: major,
      minor: minor,
      firstPref: firstPref,
      secondPref: secondPref,
      phone_number: phoneNumber,
      classStanding: standing,
      firstPrefProject: firstProj,
      secondPrefProject: secondProj,
    })
    // console.log('submitted')
  }

  function handleSubmit(event) {
    event.preventDefault()
    // console.log(firstName, lastName, email, id, major, minor, firstPref, standing)
    submitApplicant()
  }

  const handleFirstPref = event => {
    setFirstPref(event.target.value)
    setProjects([])
    setFirstProj('')
  }
  const handleSecondPref = event => {
    setSecondPref(event.target.value)
    setProjects2([])
    setSecondProj('')
  }
  const handleClassStanding = event => {
    setStanding(event.target.value)
  }
  const handleFirstProj = event => {
    setFirstProj(event.target.value)
  }
  const handleSecondProj = event => {
    setSecondProj(event.target.value)
  }

  // use the api
  React.useEffect(() => {
    fetch('https://api.aucsu.org/api/v1/recruitment/committees')
      .then(response => response.json())
      .then(data =>
        data.map(committee =>
          setCommittees(oldArray => [
            ...oldArray,
            <MenuItem value={committee.id} key={committee.id}>
              {committee.committee_name}
            </MenuItem>,
          ])
        )
      )
  }, [])

  React.useEffect(() => {
    if (firstPref === '') return
    fetch(`https://api.aucsu.org/api/v1/recruitment/projects/${firstPref}`)
      .then(response => response.json())
      .then(data =>
        data.map(project =>
          setProjects(oldArray => [
            ...oldArray,
            <MenuItem value={project.project_id} key={project.project_id}>
              {project.project_name}
            </MenuItem>,
          ])
        )
      )
  }, [firstPref])

  React.useEffect(() => {
    if (secondPref === '') return
    fetch(`https://api.aucsu.org/api/v1/recruitment/projects/${secondPref}`)
      .then(response => response.json())
      .then(data =>
        data.map(project =>
          setProjects2(oldArray => [
            ...oldArray,
            <MenuItem value={project.project_id} key={project.project_id}>
              {project.project_name}
            </MenuItem>,
          ])
        )
      )
  }, [secondPref])

  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <CardWithShadow
          sx={{
            flex: 1,
            flexDirection: 'column',
            p: 10,
            display: 'flex',
            maxWidth: '80%',
            maxHeight: '80%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              // flexGrow: 1,
              flex: 0.3,
              fontSize: { xs: 25, sm: 36 },
              fontWeight: 700,
            }}
          >
            Please sign in to access the form.
          </Typography>
          <SignInButton OnSignIn={signIn} />
        </CardWithShadow>
      </Box>
    )
  } else if (status === 1 && submitted === false) {
    return (
      <div id="body">
        <Box
          id="title"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1>Recruitment Form</h1>
        </Box>
        <a href="https://drive.google.com/file/d/1Gi1ub2yc60uP2eBvvr73EoUK43x5EI_p/view?usp=sharing">
          Click here to open SU Committee Structures document
        </a>
        <Box
          id="title"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 4,
          }}
        >
          {' '}
          <React.Fragment>
            <form onSubmit={handleSubmit} action={<Link to="/login" />} id="form">
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="First Name"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Last Name"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                  fullWidth
                  required
                />
              </Stack>
              {/* <TextField
                type="email"
                variant="outlined"
                color="secondary"
                label="Email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                fullWidth
                required
                sx={{ mb: 4 }}
              /> */}
              <TextField
                type="id"
                variant="outlined"
                color="secondary"
                label="id"
                onChange={e => setId(e.target.value)}
                value={id}
                required
                fullWidth
                sx={{ mb: 4 }}
              />
              <TextField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                variant="outlined"
                color="secondary"
                label="Phone Number"
                onChange={e => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                required
                fullWidth
                sx={{ mb: 4 }}
              />
              <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Major"
                  onChange={e => setMajor(e.target.value)}
                  value={major}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant="outlined"
                  color="secondary"
                  label="Minor"
                  onChange={e => setMinor(e.target.value)}
                  value={minor}
                  fullWidth
                />
              </Stack>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel id="classStandingLabel">Class Standing</InputLabel>
                <Select
                  labelId="classStandingLabel"
                  id="classStanding"
                  value={standing}
                  label="Class Standing"
                  onChange={handleClassStanding}
                  fullWidth
                  required
                >
                  <MenuItem value={'Freshman'}>Freshman</MenuItem>
                  <MenuItem value={'Sophomore'}>Sophomore</MenuItem>
                  <MenuItem value={'Junior'}>Junior</MenuItem>
                  <MenuItem value={'Senior'}>Senior</MenuItem>
                  <MenuItem value={'Graduating Senior'}>Graduating Senior</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="firstPrefLabel">First Preference</InputLabel>
                <Select
                  labelId="firstPrefLabel"
                  id="firstPref"
                  value={firstPref}
                  label="First Preference"
                  onChange={handleFirstPref}
                  fullWidth
                  required
                >
                  {committees}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 4 }}>
                <InputLabel id="firstProjLabel">First Project</InputLabel>
                <Select
                  labelId="firstProjLabel"
                  id="firstProj"
                  value={firstProj}
                  label="First Project"
                  onChange={handleFirstProj}
                  fullWidth
                >
                  {projects}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 4 }}>
                <InputLabel id="secondPrefLabel">Second Preference</InputLabel>
                <Select
                  labelId="secondPrefLabel"
                  id="secondPref"
                  value={secondPref}
                  label="Second Preference"
                  onChange={handleSecondPref}
                  fullWidth
                  required
                >
                  {committees}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 4 }}>
                <InputLabel id="secondProjLabel">Second Project</InputLabel>
                <Select
                  labelId="secondProjLabel"
                  id="secondProj"
                  value={secondProj}
                  label="Second Project"
                  onChange={handleSecondProj}
                  fullWidth
                >
                  {projects2}
                </Select>
              </FormControl>
              <Button id="submit" variant="outlined" color="secondary" type="submit">
                Submit
              </Button>
            </form>
          </React.Fragment>
        </Box>
      </div>
    )
  } else if (submitted === true) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <CardWithShadow
          sx={{
            flex: 1,
            flexDirection: 'column',
            p: 10,
            display: 'flex',
            maxWidth: '80%',
            maxHeight: '80%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              // flexGrow: 1,
              flex: 0.3,
              fontSize: { xs: 25, sm: 36 },
              fontWeight: 700,
            }}
          >
            You have already submitted the form.
          </Typography>
        </CardWithShadow>
      </Box>
    )
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <CardWithShadow
          sx={{
            flex: 1,
            flexDirection: 'column',
            p: 10,
            display: 'flex',
            maxWidth: '80%',
            maxHeight: '80%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              // flexGrow: 1,
              flex: 0.3,
              fontSize: { xs: 25, sm: 36 },
              fontWeight: 700,
            }}
          >
            The form is not available at the moment.
          </Typography>
        </CardWithShadow>
      </Box>
    )
  }
}

export default RecruitmentForm
