const { Box } = require('@mui/system')

const CardWithShadow = props => {
  const { sx, children, ...rest } = props
  return (
    <Box
      sx={{
        borderRadius: '100px',
        display: 'flex',
        border: '1px solid #000',
        flex: 1,
        width: '100%',
        boxShadow: '25px 25px grey',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
export default CardWithShadow