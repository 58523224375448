import { default as AuthProvider } from './AuthProvider'
import { default as LocalizationProvider } from './LocalizationProvider'
import { default as ReactQueryProvider } from './ReactQueryProvider'
import { default as ToastProvider } from './ToastProvider'
import { default as ThemeProvider } from './ThemeProvider'

const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <LocalizationProvider>
        <ReactQueryProvider>
          <ThemeProvider>
            <ToastProvider>{children}</ToastProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </LocalizationProvider>
    </AuthProvider>
  )
}

export default AppProvider

export * from './AuthProvider'
export * from './LocalizationProvider'
export * from './ReactQueryProvider'
export * from './ToastProvider'
export * from './ThemeProvider'
