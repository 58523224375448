import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import './styles.css'
import Loading from 'components/common/loading'
import { useQueryClient, useQuery } from 'react-query'
import { getNotes } from 'api/studynotes.service'
import NoteCard from './noteCard'
import LogIn from './login'
import { useAuth } from 'context'

export default function NoteList() {
  const { user, signIn } = useAuth()

  const { course } = useParams()
  const getNotesApi = useQuery('getNotes', () => getNotes(course), {
    enabled: false,
    stateTime: Infinity,
  })
  const notesTemp = getNotesApi?.data?.data?.data ?? []
  const [notes, setNotes] = React.useState(notesTemp)
  React.useEffect(() => {
    getNotesApi.refetch().then(() => {
      // if()
      setNotes(getNotesApi?.data?.data?.data ?? [])
      // console.log(getNotesApi?.data?.data?.data ?? [])
    })
  }, [notesTemp])

  if (!user) return <LogIn />

  if (getNotesApi.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <Loading />
      </Box>
    )
  } else if (notes.length === 0) {
    return (
      <Box
        id="card-container"
        sx={{
          mx: 4,
          mt: 10,
          mb: 3,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            mt: 2,
            mb: 5,
          }}
        >
          No notes found for this course
        </Typography>
      </Box>
    )
  } else {
    return (
      <Box
        id="card-container"
        sx={{
          mx: 4,
          mt: 10,
          mb: 3,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {notes.map(note => (
          <NoteCard key={note.note_id} note={note} />
        ))}
      </Box>
    )
  }
}

// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'

// import Box from '@mui/material/Box'
// import { Typography } from '@mui/material'
// import './styles.css'
// import Loading from 'components/common/loading'
// import { useQueryClient, useQuery } from 'react-query'
// import { getNotes } from 'api/studynotes.service'
// import NoteCard from './noteCard'

// export default function NoteList() {
//   const { course } = useParams()

//   const getNotesApi = useQuery('getNotes', () => getNotes(course), {
//     enabled: false,
//     stateTime: Infinity,
//   })
//   const notesTemp = getNotesApi?.data?.data?.data ?? []

//   const [notes, setNotes] = React.useState(notesTemp)

//   React.useEffect(() => {
//     getNotesApi.refetch().then(() => {
//       setNotes(getNotesApi?.data?.data?.data ?? [])
//     })
//   }, [notesTemp])

//   if (getNotesApi.isLoading) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flex: 1,
//           height: '100vh', // Adjust height for mobile screens
//           width: '100%',
//         }}
//       >
//         <Loading />
//       </Box>
//     )
//   } else if (notes.length === 0) {
//     return (
//       <Box
//         id="card-container"
//         sx={{
//           mx: 2, // Reduce margin for smaller screens
//           mt: 4, // Adjust top margin for mobile
//           mb: 3,
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           gap: 2,
//         }}
//       >
//         <Typography
//           variant="h5" // Adjust font size for mobile
//           component="h1"
//           gutterBottom
//           sx={{
//             mt: 2,
//             mb: 3, // Adjust bottom margin for mobile
//           }}
//         >
//           No notes found for this course
//         </Typography>
//       </Box>
//     )
//   } else {
//     return (
//       <Box
//         id="card-container"
//         sx={{
//           mx: 2, // Reduce margin for smaller screens
//           mt: 4, // Adjust top margin for mobile
//           mb: 3,
//           display: 'flex',
//           flexDirection: 'column', // Change to column layout for mobile
//           gap: 2,
//         }}
//       >
//         {notes.map(note => (
//           <NoteCard key={note.note_id} note={note} />
//         ))}
//       </Box>
//     )
//   }
// }
