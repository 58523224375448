import React, { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

export default function CategoryList() {
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState('')

  const navigate = useNavigate()

  React.useEffect(() => {
    fetch('https://api.aucsu.org/api/v1/notes/categories')
      .then(response => response.json())
      .then(data =>
        data.data.map(category =>
          setCategories(oldArray => [
            ...oldArray,
            <MenuItem value={category.cat_prefix} key={category.cat_ID}>
              {`${category.cat_prefix} - ${category.cat_name}`}
            </MenuItem>,
          ])
        )
      )
  }, [])

  const handleCategory = event => {
    setCategory(event.target.value)
  }

  return (
    <Box
      id="title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        width: '100%',
      }}
    >
      {/* <Typography variant="h3" component="h1" gutterBottom>
        CHOOSE A CATEGORY TO VIEW ITS COURSES
      </Typography> */}

      {/* navigate('CourseList', { state: category }) */}

      <form onSubmit={() => navigate(`/study-notes/${category}`)}>
        <Stack sx={{ mt: 3, mb: 3 }}>
          <FormControl
            sx={{
              minWidth: 300,
            }}
          >
            <InputLabel id="categoriesLabel">Categories</InputLabel>
            <Select
              labelId="categoriesLabel"
              id="categories"
              value={category}
              label="Categories"
              onChange={handleCategory}
              // fullWidth
              required
            >
              {categories}
            </Select>
          </FormControl>
          <Button id="submit" variant="outlined" color="secondary" type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  )
}
