import React, { useState } from 'react'
import { Button, Divider } from '@mui/material'
import { ArrowBack, ShoppingCart } from '@mui/icons-material'
import { useAuth } from 'context'
import Loading from 'components/common/loading'
import { useQueryClient } from 'react-query'
import './style.css'
import { useCheckout, useGetCart, usePoints, useRemoveItem } from 'api/packs'
import AreYouSureBro from 'components/common/are-you-sure-bro'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const SUPacksCheckout = () => {
  const { user } = useAuth()
  const [removeModalOptions, setRemoveModalOptions] = useState({ open: false, name: '', id: 0 })
  const [checkoutModal, setCheckoutModal] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data: cartData, isLoading } = useGetCart(user?.google_id)
  const { mutate: removeItem, isLoading: isRemovingItem } = useRemoveItem({
    onSuccess: () => {
      queryClient.invalidateQueries('su-pack-user-points')
      queryClient.invalidateQueries(['su-pack-get-cart', user?.google_id])
      toast.success('Item removed from cart successfuly!')
      setRemoveModalOptions({ open: false, name: '', id: 0 })
    },
    onError: () => {},
  })

  const { mutate: checkoutMutate, isLoading: isCheckingOut } = useCheckout({
    onSuccess: () => {
      queryClient.invalidateQueries(['su-pack-access', user?.google_id])
      toast.success('Checkout successful!')
      setCheckoutModal(false)
      navigate('/su-packs')
    },
    onError: () => {
      toast.error('Error checking out!')
    },
  })

  return (
    <div className="su-packs-container">
      <Button
        variant="contained"
        color="primary"
        href={`/su-packs/`}
        style={{ color: 'white', gap: '10px' }}
      >
        <ArrowBack />
        Back
      </Button>
      <div className="su-packs-checkout">
        {isLoading ? (
          <Loading />
        ) : (
          cartData && (
            <>
              <h2>Checkout</h2>
              <div className="su-packs-item-choices">
                {cartData.map(choice => (
                  <div className="choice-card" key={choice.itemSubchoice_id} style={{ width: 300 }}>
                    <img src={choice.imageURL} alt={choice.subchoice_name} />
                    <h2 style={{ fontSize: '1.3rem' }}>{choice.subchoice_name}</h2>
                    <p>Price: {choice.price}</p>
                    <p>Quantity: {choice.quantity}</p>
                    <Button
                      color="warning"
                      onClick={() => {
                        setRemoveModalOptions({
                          open: true,
                          name: choice.subchoice_name,
                          id: choice.itemSubchoice_id,
                        })
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                {cartData.length === 0 && <h2>No items in cart</h2>}
              </div>
              <Divider sx={{ marginY: '1rem', width: '100%' }} />
              <h2>
                Total Points: {cartData.reduce((acc, curr) => acc + curr.price * curr.quantity, 0)}
              </h2>
              <Button
                variant="contained"
                color="primary"
                style={{ color: 'white', gap: '10px' }}
                onClick={() => {
                  setCheckoutModal(true)
                }}
              >
                <ShoppingCart />
                Checkout
              </Button>

              <AreYouSureBro
                open={removeModalOptions.open}
                setOpen={o => {
                  setRemoveModalOptions({ ...removeModalOptions, open: o })
                }}
                title={`Are you sure you want to remove ${removeModalOptions.name}?`}
                description={`You might not be able to get it back!`}
                loading={isRemovingItem}
                onClick={() => {
                  removeItem({ google_id: user?.google_id, id: removeModalOptions.id })
                }}
              />

              <AreYouSureBro
                open={checkoutModal}
                setOpen={o => {
                  setCheckoutModal(o)
                }}
                title={`Are you sure you want to checkout?`}
                description={`You won't be able to make any further changes!`}
                loading={isCheckingOut}
                onClick={() => {
                  checkoutMutate(user?.google_id)
                }}
              />
            </>
          )
        )}
      </div>
    </div>
  )
}

export default SUPacksCheckout
