import { createContext, useContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth'
import config from '../config'

import authService from 'api/auth.service'

const app = initializeApp(config.firebase)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()

const useProvideAuth = () => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(authService.getCurrentUser())

  // uncomment for development only
  // Sign in with PopUp windows
  // const signIn = async () => {
  //   const result = await signInWithPopup(auth, provider)
  //   const idToken = await result.user.getIdToken()
  //   const user = await authService.login(idToken)
  //   setUser(user)
  // }

  // // uncomment for production only
  // Sign in with Redirect
  const signIn = () => {
    localStorage.setItem('loginRedirect', 'true')
    setLoading(true)
    signInWithRedirect(auth, provider)
  }

  // Handle the result after redirect:
  useEffect(() => {
    const isRedirected = localStorage.getItem('loginRedirect')
    localStorage.removeItem('loginRedirect') // Clear the flag
    if (!isRedirected) {
      return
    }

    setLoading(true)
    getRedirectResult(auth)
      .then(async result => {
        if (result == null) {
          setLoading(false)
          return
        }
        console.log('result: ', result)
        // Extracting the ID token similar to the first function
        const idToken = await result.user.getIdToken()

        // Using the idToken to get the user from your custom authService
        const user = await authService.login(idToken)

        setUser(user)
      })
      .catch(error => {
        console.error('Error during sign-in:', error)
      })
      .finally(() => {
        setLoading(false) // This will ensure that setLoading(false) is called after the promise settles, whether it resolves or rejects
      })
  }, [])

  const signOut = async () => {
    await auth.signOut()
    authService.logout()
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (!currentUser) setUser(null)
    })
    return unsubscribe
  }, [])

  return {
    user,
    loading,
    signIn,
    signOut,
  }
}

const authContext = createContext({
  user: null,
  loading: false,
  signIn: () => {},
  singOut: () => {},
})

export const useAuth = () => {
  return useContext(authContext)
}
const AuthProvider = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default AuthProvider
