import React from 'react'
import { Divider, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import logo from 'assets/SU-LOGOwhite-400.png'
import vpPic from 'assets/vp-pic.webp'
import presidentPic from 'assets/pres-pic.webp'
import Header from 'components/common/header'
import { ArrowCircleLeftOutlined, ArrowCircleRightOutlined } from '@mui/icons-material'

const Section = ({ children, ...rest }) => {
  const { sx, ...allRest } = rest
  return (
    <Box
      sx={{
        minHeight: { xs: '', md: '65vh' },
        height: 'auto',
        ...sx,
      }}
      {...allRest}
    >
      {children}
    </Box>
  )
}

const Home = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        height: '100%',
      }}
    >
      {/* first section */}
      <Section
        sx={{
          backgroundColor: 'neutral.dark',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          width: '100%',
          px: 5,
        }}
      >
        <Box
          sx={{
            flex: 1,
            flexDirection: 'column',
            flexGrow: 1,
            color: '#fff',
            p: { xs: 3, sm: 10 },
            display: 'flex',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              flex: 0.3,
              fontSize: { xs: 42, md: 72 },
              mb: 3,
            }}
          >
            AUC STUDENT UNION
          </Typography>
          <Typography
            variant="h4"
            component="strong"
            sx={{
              fontWeight: '500',
              mb: { xs: 5 },
              fontSize: { xs: '20px', md: '20px', sm: '24px' },
            }}
          >
            The student union is an entity for the students by the students. It works on three
            mandates:{' '}
            <Typography
              sx={{
                display: 'inline',
                fontWeight: '700',
                fontSize: { xs: '20px', md: '20px', sm: '24px' },
              }}
              variant="h4"
            >
              Serve, Represent, and Entertain
            </Typography>{' '}
            the student body.
          </Typography>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            display: { xs: 'none', sm: 'flex' },
            mb: { sm: 5, md: 0 },
            mt: { sm: -10, md: 0 },
          }}
        >
          <img src={logo} alt="logo" height={400} width={400} />
        </Box>
      </Section>
      <Divider />
      <Section
        sx={{
          pt: 2,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Header title="EVENTS" />

        <Box
          sx={{
            flexDirection: { xs: 'row', md: 'row' },
            display: 'flex',
            flex: 1,
            width: '100%',
            maxHeight: '80%',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            maxWidth: '50%',
            minHeight: '45vh',
          }}
        >
          <IconButton>
            <ArrowCircleLeftOutlined fontSize={'large'} />
          </IconButton>
          <Box
            sx={{
              borderRadius: '100px',
              display: 'flex',
              border: '1px solid #000',
              flex: 1,
              width: '100%',
              boxShadow: '25px 25px grey',
              mb: 10,
              alignItems: 'center',
              height: { xs: '60%', sm: '80%' },
              justifyContent: 'center',
              maxWidth: '65%',
            }}
          >
            <Typography
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontSize: { xs: 25, sm: 36 },
                px: 10,
              }}
              variant="h3"
            >
              Stay Tuned
            </Typography>
          </Box>
          <IconButton>
            <ArrowCircleRightOutlined sx={{ ml: 2 }} fontSize={'large'} />
          </IconButton>
        </Box>
      </Section>
      <Divider />
      <Section sx={{ backgroundColor: '#d7edeb', pt: 2 }}>
        <Header title="PRESIDENTIAL OFFICE" />
        <Box
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            display: 'flex',
            px: 10,
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            minHeight: '45vh',
          }}
        >
          {[
            {
              name: 'Ahmed Khatib',
              position: 'President',
              pic: presidentPic,
            },
            {
              name: 'Farida Sarhank',
              position: 'Vice President',
              pic: vpPic,
            },
          ].map(item => (
            <Box
              key={item.name}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: { xs: 3, md: 0 },
                mx: { xs: 0, md: 4 },
              }}
            >
              <Box
                sx={{
                  borderRadius: '100px',
                  border: '2px solid #000',
                  overflow: 'hidden',
                  mx: 4,
                  height: { xs: '200px', md: '220px' },
                  width: { xs: '150px', md: '165px' },
                }}
              >
                <img
                  src={item.pic}
                  alt="logo"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
              <Typography variant="h5" component="strong" mt={2} mb={1} fontWeight={700}>
                {item.name}
              </Typography>
              <Typography variant="h5" component="strong">
                {item.position}
              </Typography>
            </Box>
          ))}
        </Box>
      </Section>
      <Divider />
    </Box>
  )
}

export default Home
