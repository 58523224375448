import CategoryList from './CategoryList'
import LogIn from './login'
import ProtectedRoute from 'components/common/protected-route'
import { useAuth } from 'context'

const StudyNotesHome = () => {
  const { user, signIn } = useAuth()

  if (!user) return <LogIn />
  else return <ProtectedRoute component={CategoryList} />
}

export default StudyNotesHome
