import { Box, Typography } from '@mui/material'

export const Header = ({ title, subtitle, ...rest }) => {
  return (
    <Box display="flex" justifyContent="center" sx={{ mb: 2 }} {...rest}>
      <Box>
        <Typography variant="h2" sx={{ mb: 1, fontSize: { xs: 35, sm: 48 } }} textAlign="center">
          {title}
        </Typography>
        <Typography variant="h4" sx={{ color: 'neutral.main' }} textAlign="center">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}

export default Header
